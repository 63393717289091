<template>
  <div>
    <SaveBar></SaveBar>
    <v-card
      flat
      class="px-5 ml-5 mt-10"
    >
      <v-card-title>{{ $t('ManageSupplierUserSecurityAccess') }}</v-card-title>
      <v-card-text>
        <v-text-field
          ref="inviteEmailSupplierUserForm"
          v-model="inviteEmailSupplierUser"
          :disabled="!currentUserIsAdmin"
          append-icon="fa-plus-circle"
          label="E-mail"
          type="email"
          :rules="[
            (value) => !!value.trim() || $t('nameRules'),
            (value) => isEmail(value) || $t('PleaseEnterAValidEmail'),
            (value) =>
              !isSupplierUserAlreadyExist(value) || $t('EmailDuplicatedInList'),
          ]"
          outlined
          @keyup.enter="inviteSupplierUser"
          @click:append="inviteSupplierUser"
        ></v-text-field>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchSupplierRoles"
          append-icon="fas fa-search"
          :label="$t('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersSupplierRoles"
          :items="supplierRoles"
          :search="searchSupplierRoles"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1],
          }"
        >
          <template v-slot:[`item.user`]="{ item }">
            <router-link
              :to="{name: 'User', params: { supplierUUID: `${$route.params.supplierUUID}`, userUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              <span v-if="item.firstname !== ' ' || item.lastname !== ' '">
                {{ item.firstname }} -
                <strong>{{ item.lastname }}</strong></span>
              <span v-else>
                <v-icon x-small>fas fa-pen</v-icon>
                {{ $t('CompleteTheProfile') }}
              </span>
            </router-link>
            <span> <br>{{ item.email }}</span>
          </template>
          <template v-slot:[`item.admin`]="{ item }">
            <v-switch
              v-model="item.admin"
              :disabled="!currentUserIsAdmin"
              color="success"
              inset
              @change="modifiedValue('roles')"
            ></v-switch>
          </template>
          <template v-slot:[`item.access`]="{ item }">
            <v-switch
              v-model="item.access"
              :disabled="!currentUserIsAdmin"
              color="success"
              inset
              @change="modifiedValue('roles')"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import isEmail from 'validator/lib/isEmail';
import SaveBar from '../../../components/saveBar/SaveBar';

const i18nData = require('./pageAdministrationUserAndSecurity.i18n.json');

export default {
  name: 'PageAdministrationUserAndSecurity',
  components: { SaveBar },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      inviteEmailSupplierUser: '',
      currentUserIsAdmin: false,
      searchSupplierRoles: '',
      headersSupplierRoles: [],
      supplierRoles: [],
      supplierRolesErrorMessage: null,
      roleModified: false,
      isEmail,
      supplier: null,
      currentModified: null,
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplier();

    /**
     * EVENT TO THE SAVE BAR
     */
    EventBus.$on('saveBarSubmit', this.submitSaveBar);
    EventBus.$on('saveBarCancel', this.cancelSaveBar);
  },
  mounted() {
    this.headersSupplierRoles = [
      { text: this.$t('user'), value: 'user' },
      { text: this.$t('admin'), value: 'admin' },
      { text: this.$t('access'), value: 'access' },
    ];
  },
  beforeDestroy() {
    EventBus.$off('saveBarSubmit', this.submitSaveBar);
    EventBus.$off('saveBarCancel', this.cancelSaveBar);
  },
  methods: {
    modifiedValue(key, ref) {
      if (this.currentModified !== key) {
        this.currentModified = key;
        EventBus.$emit('displaySaveBar', key);
        if (ref) {
          setTimeout(
            function() {
              ref.$el.querySelector('input').focus();
            }.bind(this),
            10
          );
        }
      }
    },
    submitSaveBar(key) {
      key === 'roles'
        ? this.submitSupplierAccessSecurity()
        : this.$notification.notify('DANGER');
    },
    cancelSaveBar(key) {
      this.getSupplier();
    },
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID).then(
        (supplierData) => {
          this.supplier = supplierData;
          this.getSupplierRoles();
          this.currentModified = null;
          EventBus.$emit('hideSaveBar');
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * PARSING SUPPLIER ROLES
     */
    parseSupplierRoles(supplierRolesData) {
      const supplierRolesDataCopy = JSON.parse(
        JSON.stringify(supplierRolesData)
      );
      const supplierRolesArray = [];
      for (const roleUserMapping of supplierRolesDataCopy.access) {
        if (!(roleUserMapping._id in supplierRolesArray)) {
          supplierRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        supplierRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of supplierRolesDataCopy.admin) {
        if (!(roleUserMapping._id in supplierRolesArray)) {
          supplierRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        supplierRolesArray[roleUserMapping._id].admin = true;
      }
      const supplierRoles = [];
      for (const userId in supplierRolesArray) {
        if (!supplierRolesArray[userId].access) {
          supplierRolesArray[userId].access = false;
        }
        if (!supplierRolesArray[userId].admin) {
          supplierRolesArray[userId].admin = false;
        }
        supplierRoles.push(supplierRolesArray[userId]);
      }
      this.supplierRoles = supplierRoles;
    },

    /**
     * CHECK IF USER EXIT IN THIS SUPPLIER
     */
    isSupplierUserAlreadyExist(email) {
      let found = false;
      this.supplierRoles.forEach((user) => {
        if (user.email.toLowerCase() === email.toLowerCase()) {
          found = true;
          return found;
        }
      });
      return found;
    },
    onTabChange() {
      if (this.currentModified) {
        this.getSupplier();
      }
    },
    /**
     * CHANGE ROLES
     */
    roleChange() {
      this.roleModified = true;
    },
    /**
     * INVITE USER TO THIS SUPPLIER
     */
    inviteSupplierUser() {
      if (this.$refs.inviteEmailSupplierUserForm.validate(true)) {
        const inviteSupplierUserBody = new this.$BcmModel.InviteSupplierUserBody(
          this.inviteEmailSupplierUser
        );
        this.$apiInstance
          .inviteSupplierUser(
            this.$route.params.supplierUUID,
            inviteSupplierUserBody
          )
          .then(
            (data) => {
              this.getSupplierRoles();
              this.inviteEmailSupplierUser = '';
              this.$notification.notify('SUCCESS', this.$t('HasBeenSuccessfullyAdded', { email: data.email }));
            },
            (error) => {
              /**
               * ERROR INVITE SUPPLIER USER
               */
              this.globalErrorMessage = ApiErrorParser.parse(error);
            }
          );
      }
    },
    /**
     * SUBMIT SUPPLIER ROLES ACCESS / ADMIN
     */
    submitSupplierAccessSecurity() {
      const rolesUserMapping = new this.$BcmModel.Roles();
      for (const role in rolesUserMapping) {
        rolesUserMapping[role] = [];
      }
      for (const userMapping of this.supplierRoles) {
        if (userMapping.access) {
          rolesUserMapping.access.push(userMapping._id);
        }
        if (userMapping.admin) {
          rolesUserMapping.admin.push(userMapping._id);
        }
      }
      this.$apiInstance
        .modifySupplierRoles(this.$route.params.supplierUUID, rolesUserMapping)
        .then(
          (data) => {
            this.supplierRolesData = data;
            this.parseSupplierRoles(this.supplierRolesData);
            this.roleModified = false;
            this.getSupplier();
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS', this.$t('RoleSuccessfullyModified'));
          },
          (error) => {
            /**
             * ERROR SAVE SUPPLIER ROLES
             */
            this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * RESET SUPPLIER ROLES ACCESS / ADMIN
     */
    cancelSupplierAccessSecurity() {
      this.parseSupplierRoles(this.supplierRolesData);
      this.roleModified = false;
    },
    /**
     * GET SUPPLIER ROLES
     */
    getSupplierRoles() {
      this.$apiInstance.getSupplierRoles(this.$route.params.supplierUUID).then(
        (supplierRolesData) => {
          this.supplierRolesData = supplierRolesData;
          this.roleModified = false;
          this.supplierRolesData.admin.forEach((user) => {
            if (user._id === this.$appConfig.user._id) {
              this.currentUserIsAdmin = true;
            }
          });
          this.parseSupplierRoles(supplierRolesData);
        },

        (error) => {
          /**
           * ERROR GET SUPPLIER ROLES
           */
          this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>

